import { createSlice } from '@reduxjs/toolkit';

const CasesSlice = createSlice({
    name: 'cases',
    initialState: {
        cases: [
            {
              id:1,
              pic:'https://picsum.photos/id/15/200',
              name:'Case One',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 2,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 1
            },
            {
              id:2,
              pic:'https://picsum.photos/id/16/200',
              name:'Case Two',
              desc:'Lorem ipsum dolor sit ame123t, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 3.5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:3,
              pic:'https://picsum.photos/id/17/200',
              name:'Case Three',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 2,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:4,
              pic:'https://picsum.photos/id/18/200',
              name:'Case Four',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 4.5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:5,
              pic:'https://picsum.photos/id/15/200',
              name:'Case Five',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 4,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 5,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 6,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:6,
              pic:'https://picsum.photos/id/16/200',
              name:'Case Six',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 4,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:7,
              pic:'https://picsum.photos/id/17/200',
              name:'Case Seven',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 1.5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:8,
              pic:'https://picsum.photos/id/18/200',
              name:'Case Eight',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:9,
              pic:'https://picsum.photos/id/852/200',
              name:'Case Nine',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 0.5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
            {
              id:10,
              pic:'https://picsum.photos/id/741/200',
              name:'Case Ten',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              rating: 5,
              reviews:[
                {
                  id: 1,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 2,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
                {
                  id: 3,
                  comment:'Nice Service & products',
                  date: '2022-01-01',
                  user: 'John Doe'
                },
              ],
              isDel: 0
            },
        ],
        
    },
    reducers: {
        updateCaseName: (state, action) => {
            state.cases.map(item => {
                if(item.id === action.payload.case_id){
                    item.name = action.payload.name;
                    return item;
                }
                return item;
            });
        },
        addCase: (state, action) => {
            state.cases.push(action.payload);
        },
        deleteCase: (state, action) => {
            state.cases.map(item => {
                if(item.id === action.payload.case_id){
                    item.isDel = 1;
                    return item;
                }
                return item;
            });
        }
    }
})

export const { updateCaseName, addCase, deleteCase } = CasesSlice.actions;

export default CasesSlice.reducer;
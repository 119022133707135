import { createContext, useState } from "react";
// import multiavatar from '@multiavatar/multiavatar/esm'

// const getAvatar = () => {
//     let id = generateRandomString(5, 10)
//     return multiavatar(id)
// }


// function generateRandomString(min, max) {
//     let result = '';
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     const charactersLength = characters.length;

//     for (let i = 0; i < Math.floor(Math.random() * (min - max) + max); i++) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }

//     return result;
// }

const UserContext = createContext()

export const UserProvider = ({children}) => {
    let regUsers = JSON.parse(localStorage.getItem("users"))||[]
    let currentUser = JSON.parse(localStorage.getItem("user"))||{}
    let loginStatus = JSON.parse(localStorage.getItem("isLogin"))||false
    const [isLogin, setIsLogin] = useState(loginStatus)
    const [users, setUsers] = useState(regUsers)
    const [user, setCurrentUsers] = useState(currentUser)
    return (
        <UserContext.Provider value={{users, setUsers, isLogin, setIsLogin, user, setCurrentUsers}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;
import React from 'react'
import '../assets/AboutUs.scss'
import { setTitleTagText } from '../utils/common';
import PageHeader from '../components/layout/PageHeader';

window.scrollTo(0, 0);
const AboutUs = () => {

  setTitleTagText('About ConnectCo | Find Services | Find Partners | Promotions');
  return (
    <>
      <PageHeader pageTitle="About ConnectCo" img="/assets/images/aboutpageHead.webp"/>
      <div className='container down90'>
        <div className="aboutUs">
          <div className="aboutImg">
            <img src="/assets/images/about-page.webp" alt="about page" width='100%' height='auto' />
          </div>
          <span className='contentTitle'>What ConnectCo Does?</span>
          <p className="content">
          ConnectCo SG Pte Ltd specializes in providing innovative outsourced sales and marketing services tailored to the unique needs of businesses across various industries. We also offer business management and landing services to enhance operational efficiency and facilitate market expansion. Our commitment to delivering effective solutions ensures that our clients achieve growth and success. Partner with ConnectCo SG to elevate your business to new heights.
          </p>
        </div>
        <div className="aboutservice">
          <div className="aboutserviceImg">
            <img src="/assets/images/about-page.webp" alt="about page" width='100%' height='auto' />
          </div>
          <p className="content">
            <span className='contentTitle'>We Provide <br />Innovative Outsourced Sales</span>
          ConnectCo SG Pte Ltd specializes in providing innovative outsourced sales and marketing services tailored to the unique needs of businesses across various industries. We also offer business management and landing services to enhance operational efficiency and facilitate market expansion. Our commitment to delivering effective solutions ensures that our clients achieve growth and success. Partner with ConnectCo SG to elevate your business to new heights.
          </p>
        </div>
        <div className="aboutservice reverse">
          <div className="aboutserviceImg">
            <img src="/assets/images/about-page.webp" alt="about page" width='100%' height='auto' />
          </div>
          <p className="content">
            <span className='contentTitle'>We Provide <br />Customised Marketing Services</span>
          ConnectCo SG Pte Ltd specializes in providing innovative outsourced sales and marketing services tailored to the unique needs of businesses across various industries. We also offer business management and landing services to enhance operational efficiency and facilitate market expansion. Our commitment to delivering effective solutions ensures that our clients achieve growth and success. Partner with ConnectCo SG to elevate your business to new heights.
          </p>
        </div>
        <div className="aboutservice">
          <div className="aboutserviceImg">
            <img src="/assets/images/about-page.webp" alt="about page" width='100%' height='auto' />
          </div>
          <p className="content">
            <span className='contentTitle'>We Provide <br />Business Management & Landing Services</span>
          ConnectCo SG Pte Ltd specializes in providing innovative outsourced sales and marketing services tailored to the unique needs of businesses across various industries. We also offer business management and landing services to enhance operational efficiency and facilitate market expansion. Our commitment to delivering effective solutions ensures that our clients achieve growth and success. Partner with ConnectCo SG to elevate your business to new heights.
          </p>
        </div>
      </div>
    </>
  )
}

export default AboutUs

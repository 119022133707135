import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import PartnerContext from '../context/PartnersContext'
import '../assets/PartnersDetails.scss'

const PartnerDetail = () => {
    const { partners } = useContext(PartnerContext)
    const { id } = useParams()
    let currentPartner = partners.find(partner => partner.id === parseInt(id))
    window.scrollTo(0, 0);
    return (
    <div className='container down90'>
        <div className="partnerDetails">
            <div className="partnerInfo">
                <div className="partnerPreview">
                    <img src={currentPartner.pic} alt={currentPartner.pic} width='100%'/>
                </div>
                <div className="partnerDetail">
                    <h1 className="itemTitle">{currentPartner.name}</h1>
                    <p className="itemPara">{currentPartner.desc}</p>
                    <h3 className="priceRange">
                        Price Range: 
                        <span className="priceFrom">${currentPartner.priceFrom}</span>
                        <span className="seperator"> - </span>
                        <span className="priceTo">${currentPartner.priceTo}</span>
                    </h3>
                </div>
            </div>
            {/* <div className="partnerComments">
                <div className="comment"></div>
                <div className="commentForm"></div>
            </div> */}
        </div>
    </div>
    )
}

export default PartnerDetail
export const filteredIsDel = (state) => {
    return state.filter(x => x.isDel === 0);
};

// eslint-disable-next-line
export const filteredItem = (state, id) => {
    if(state.filter(x => parseInt(x.id) === parseInt(id)).length > 0){
        return state.filter(x => parseInt(x.id) === parseInt(id))[0];
    }
    return false;
};
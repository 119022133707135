import PageHeader from '../components/layout/PageHeader';
import '../assets/AllPartners.scss'
import { setTitleTagText } from '../utils/common';
import { FaBriefcase } from 'react-icons/fa';
import { useState } from 'react';

const Partners = () => {
  const [toTop, setToTop] = useState(true)
  if(toTop){
    window.scrollTo(0, 0);
    setToTop(false)
  }
  let t = null;
  const [partnerForm, setPartnerForm] = useState({
    companyName: '',
    CompanyRegistrationNo: '',
    personInCharge: '',
    picEmail: '',
    picContactNo: ''
  });
  const [partnerFormError, setPartnerFormError] = useState(false);
  const [partnerFormSuccess, setPartnerSuccess] = useState(false);
  const handlePartnerFormChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPartnerForm({ ...partnerForm, [name]: value });
  }

  const clearFormInput = ()=>{
    document.querySelectorAll('.formInput input').forEach((input) => input.value = '');
  }
  const handlePartnerFormSubmit = (e) => {
    e.preventDefault();
    // Validate form
    if (partnerForm.companyName || partnerForm.CompanyRegistrationNo || partnerForm.personInCharge || partnerForm.picEmail || partnerForm.picContactNo){      
      setPartnerFormError(true);
    }else{
      setPartnerFormError(false);
    };
    if(partnerFormError){
      alert('Please fill all the fields');
    }else{
      alert(`Company Name: ${partnerForm.companyName}\nCompany Registration No: ${partnerForm.CompanyRegistrationNo}\nPerson In Charge: ${partnerForm.personInCharge}\nPIC Email: ${partnerForm.picEmail}\nPIC Contact No: ${partnerForm.picContactNo}`);
      setPartnerForm({ companyName: '', CompanyRegistrationNo: '', personInCharge: '', picEmail: '', picContactNo: '' });
      clearFormInput()
      setPartnerSuccess(true)
      t = setTimeout(() => {
        setPartnerSuccess(false)
      },2000)
    }
  }
  setTitleTagText('Be a Partner | Find Services | Find Partners | Promotions');
  return (
    <>
      <PageHeader pageTitle="Become Our Partner" img="/assets/images/partnerHead.webp"/>
      <div className='container down90'>
        <div className="allPartners">
          <h2 className='partnerTitle'>Benefits of Becoming Our Partner</h2>
          <div className="benefitsItems">
            <div className="item">
              <div className="itemIcon"><FaBriefcase /></div>
              <h3 className="itemTitle">Showcase & Promote Product</h3>
              <p className="itemPara">We provide a place for you to showcasing your products, and prmote it to our other partners.</p>
            </div>
            <div className="item">
              <div className="itemIcon"><FaBriefcase /></div>
              <h3 className="itemTitle">Showcase & Promote Product</h3>
              <p className="itemPara">We provide a place for you to showcasing your products, and prmote it to our other partners.</p>
            </div>
            <div className="item">
              <div className="itemIcon"><FaBriefcase /></div>
              <h3 className="itemTitle">Showcase & Promote Product</h3>
              <p className="itemPara">We provide a place for you to showcasing your products, and prmote it to our other partners.</p>
            </div>
            <div className="item">
              <div className="itemIcon"><FaBriefcase /></div>
              <h3 className="itemTitle">Showcase & Promote Product</h3>
              <p className="itemPara">We provide a place for you to showcasing your products, and prmote it to our other partners.</p>
            </div>
            <div className="item">
              <div className="itemIcon"><FaBriefcase /></div>
              <h3 className="itemTitle">Showcase & Promote Product</h3>
              <p className="itemPara">We provide a place for you to showcasing your products, and prmote it to our other partners.</p>
            </div>
            <div className="item">
              <div className="itemIcon"><FaBriefcase /></div>
              <h3 className="itemTitle">Showcase & Promote Product</h3>
              <p className="itemPara">We provide a place for you to showcasing your products, and prmote it to our other partners.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="reachOut">
        <div className="reachOutContent">
          <div className="overlay"></div>
          <img src="/assets/images/reach-out-more-people.webp" className='reachOutImg' alt="reach-out-more-people" width='100%' />
          <div className="reachOutPhase">
            <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates ut asperiores quis temporibus ratione fuga.</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="formArea">
          <div className="formImg">
            <img src="/assets/images/about-page.webp" alt="form image" width='100%' />
          </div>
          {!partnerFormSuccess?(<form id="partnerForm" onSubmit={handlePartnerFormSubmit}>
            <h3 className="formTitle">Be our Partner!</h3>
            <div className="formInput companyName">
              <input type="text" onChange={handlePartnerFormChange} placeholder="Company Name" id="companyName" name="companyName" required />
            </div>
            <div className="formInput personInCharge">
              <input type="text" onChange={handlePartnerFormChange} placeholder="Person In Charge Name" id="personInCharge" name="personInCharge" required />
            </div>
            <div className="formInput CompanyRegistrationNo">
              <input type="text" onChange={handlePartnerFormChange} placeholder="Company Registration No" id="CompanyRegistrationNo" name="CompanyRegistrationNo" required />
            </div>
            <div className="formInput picContactNo">
              <input type="text" onChange={handlePartnerFormChange} placeholder="Person In Charge Contact No" id="picContactNo" name="picContactNo" required />
            </div>
            <div className="formInput picEmail">
              <input type="email" onChange={handlePartnerFormChange} placeholder="Person In Charge Email" id="picEmail" name="picEmail" required />
            </div>
            <div className="formInput submit">
              <button type="submit" className="btn submit">Submit</button>
            </div>
          </form>):(
            <div className="successSubmit">
              <h2 className="successMsg">Partner Application Submitted Successfully!</h2>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Partners

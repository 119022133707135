import { createBrowserRouter } from "react-router-dom";
import Main from "../views/Main";
import Partners from "../views/Partners";
import Services from "../views/Services";
import CaseStudy from "../views/CaseStudy";
import MemberCenter from "../views/MemberCenter";
import AboutUs from "../views/AboutUs";
import ServiceDetail from "../components/ServiceDetail";
import PartnerDetail from "../components/PartnerDetail";
import CaseDetail from "../components/CaseDetail";
import App from "../App";
import ErrorPage from "../views/ErrorPage";
import PromotionDetail from "../components/PromotionDetail";

const routes = [
    {
        path: "/",
        element: <Main />
    },
    {
        path: "/partners",
        element: <Partners />
    },
    {
        path: "partner/:id",
        element: <PartnerDetail />
    },
    // {
    //     path: "/promotions",
    //     element: <Promotions />
    // },
    {
        path: "promotion/:id",
        element: <PromotionDetail />
    },
    {
        path: "/services",
        element: <Services />
    },
    {
        path: "services/:id",
        element: <ServiceDetail />
    },
    {
        path: "/case-study",
        element: <CaseStudy />
    },
    {
        path: "case-study/:id",
        element: <CaseDetail />
    },
    {
        path: "/member-center",
        element: <MemberCenter />
    },
    {
        path: "/about-us",
        element: <AboutUs />
    }
]
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: routes
    },
])
export default router;
import { BsQuote } from 'react-icons/bs'
import '../assets/Reviews.scss'

const Reviews = ({reviewItem={
    id: 1,
    comment:'Nice Service & products',
    date: '2022-01-01',
    user: 'John Doe'
  }
}) => {
    return (
        <>
            <div className="reviewsitem">
                <div className="comments">
                    <BsQuote className='quote quote-left'/>
                    <p className="comment">{reviewItem.comment}</p>
                    <BsQuote className='quote quote-right'/>
                </div>
                <div className="authorNDate">
                    <p className="author">{reviewItem.user}</p>
                    <p className="date">{reviewItem.date}</p>
                </div>
            </div>
        </>
    )
}

export default Reviews
import { createSlice } from '@reduxjs/toolkit';

const GeneralSlice = createSlice({
    name: 'user',
    initialState: {
        msgTime: 1,
        token:'',
        phone:'',
        currentPage:'',
        domain:''
    },
    reducers: {
        updateToken: (state, action) => {
            console.log('set token at redux');
            state.token = action.payload;
        },
        updatePage: (state, action) => {
            state.currentPage = action.payload;
        },
        removeAll: (state) => {
            state.token = '';
            state.currentPage = '';
        }
    }
})

export const { updatePage, updateToken, removeAll } = GeneralSlice.actions;

export default GeneralSlice.reducer;
import React from 'react'
import '../assets/CaseStudy.scss'
import { setTitleTagText } from '../utils/common';
import { useSelector, shallowEqual } from 'react-redux';
import { filteredIsDel } from '../store/ShallowActions';
import PageHeader from '../components/layout/PageHeader';
import Banner from '../components/Banner';

window.scrollTo(0, 0);
const CaseStudy = () => {
  const cases = useSelector((state)=>filteredIsDel(state.cases.cases), shallowEqual);
  setTitleTagText('Case Study | Find Services | Find Partners | Promotions');
  return (
    <>
      <PageHeader pageTitle="Case Study" img="/assets/images/serviceHead.webp"/>
      <div className='container down90'>
        <div className="allCases">
          <p className="caseTitle">Check the cases our partner provided and how are the reviews</p>
          <Banner type='client' list={cases} />
        </div>
      </div>
    </>
  )
}

export default CaseStudy

import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './UserSlice.js'
import GeneralReducer from './GeneralSlice.js'
import PartnerReducer from './PartnerSlice.js';
import ServiceReducer from './ServiceSlice.js';
import CasesReducer from './CasesSlice.js';
import PromotionReducer from './PromoteSlice.js';

const store = configureStore({
    reducer: {
        user: UserReducer,
        general: GeneralReducer,
        partner: PartnerReducer,
        service: ServiceReducer,
        cases: CasesReducer,
        promotion: PromotionReducer,
    }
})

export default store;
// import Marquee from "react-fast-marquee";
// import {FaBell, FaBars} from 'react-icons/fa'
import Banner from '../components/Banner'
import '../assets/Main.scss'
// import { useState, useContext } from 'react';
// import { useContext } from 'react';
// import { Link } from 'react-router-dom'
import SearchResult from '../components/SearchResult';
import { useState } from 'react';
import { setTitleTagText } from '../utils/common.js';
import { FaSearch } from 'react-icons/fa';

const Main = () => {
  // const dispatch = useDispatch();
  setTitleTagText('Find Services | Find Partners | Promotions');
  const [searchData, setSearchData] = useState({});
  const [dataFilter, setDataFilter] = useState({});
  const [canSearch, setCanSearch] = useState(false);
  const getSearchData = (e) =>{
    setCanSearch(true);
    if(e.target.value === ''){
      setDataFilter({});
      setSearchData({})
      setCanSearch(false);
      goto('/');
      return;
    }
    setSearchData(prev=> prev = {...prev, [e.target.name]: e.target.value})
    setCanSearch(false);
  }

  const goto = (url)=>{
    let aLink = document.createElement('a');
    aLink.href = url;
    aLink.click();
    aLink.remove();
  }
  let t = null;
  const filterData = (e) =>{
    e.preventDefault();
    if(t !== null){
      clearTimeout(t)
    }
    setDataFilter(searchData);
    t = setTimeout(()=>{
      goto('/#searchResultWrap');
    }, 100)
  }
  // window.addEventListener('scroll', (e)=>{
  //   let bodyEl = document.body;
  //   let totalHeight = bodyEl.scrollHeight;
  //   let the100 = totalHeight - window.innerHeight;
  //   let diff = totalHeight + bodyEl.getBoundingClientRect().y - window.innerHeight
  //   // console.log(totalHeight, 'totalHeight');
  //   console.log(diff, 'rewct');
  //   console.log(the100 - diff, 'the100 - diff');
  //   // setCurrentPercentage((((the100 - diff)/the100)*100).toFixed(2));
  //   document.querySelector('.displayPercent').innerText = (((the100 - diff)/the100)*100).toFixed(2) + '%';
  //   document.querySelector('.displayPercent').style.top = diff > 250 ? (((the100 - diff)/(the100))*100).toFixed(2) + '%' : (((the100 - 250)/(the100))*100).toFixed(2) + '%';
  //   console.log((((the100 - diff)/the100)*100).toFixed(2), '%');
  // })
  // console.log(partnersData, 'partner');
  return (
    <>
      <div id='mainPage'>
        <div className='container banner'>
            <div className="promotionsBanner">
              <Banner />
            </div>
            <div className="serachItems">
              <form id="searchForm" onSubmit={filterData}>
                <input type="search" name="searchItem" id="searchItem" onChange={getSearchData} placeholder='Search Promotions...'/>
                <button type="submit" className='submitBtn' disabled={canSearch}><FaSearch/></button>
              </form>
            </div>
        </div>
      </div>
      <div id="searchResultWrap">
        <SearchResult  dataFilter={dataFilter}/>
      </div>
    </>
  )
}

export default Main
import './App.scss';
// import Main from './views/Main';
import Footer from './components/layout/Footer';
import Navbar from './components/layout/Navbar';
// import Services from './views/Services';
// import Partners from './views/Partners';
// import PartnerDetail from './components/PartnerDetail';
// import ServiceDetail from './components/ServiceDetail';
// import MemberCenter from './views/MemberCenter';
// import AboutUs from './views/AboutUs';
// import CaseStudy from './views/CaseStudy';
import RegisterPage from './views/RegisterPage';
// import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Outlet} from 'react-router-dom'
import { ServiceProvider } from './context/ServicesContext.js';
import { PartnerProvider } from './context/PartnersContext.js';
import { UserProvider } from './context/UserContext.js';
import { useState } from 'react';
import { Provider } from 'react-redux';
import store from './store/Store.js';

function App() {
  const [registerNow, setRegisterNow] = useState(false)
  const getRegisterPopUp = ()=>{
    setRegisterNow(!registerNow)
  }
  return (
    <Provider store={store}>
      <UserProvider>
        <ServiceProvider>
          <PartnerProvider>
            {/* <Router> */}
              <Navbar isMobile={false} handleRegister={getRegisterPopUp}/>
              <div className='mainApp'>
              {/* <Routes>
                <Route path='/' exact Component={Main} />
                <Route path='/services' exact Component={Services} />
                <Route path='/partners' exact Component={Partners} />
                <Route path='/partner/:id' exact Component={PartnerDetail} />
                <Route path='/services/:id' exact Component={ServiceDetail} />
                <Route path='/member-center' exact Component={MemberCenter} />
                <Route path='/about-us' exact Component={AboutUs} />
                <Route path='/case-study' exact Component={CaseStudy} />
              </Routes> */}
                <Outlet/>
                <RegisterPage registerNow={registerNow} handleRegister={getRegisterPopUp}/>
              </div>
              <Footer/>
            {/* </Router> */}
          </PartnerProvider>
        </ServiceProvider>
      </UserProvider>
    </Provider>
  );
}

export default App;

import '../assets/PromotesItem.scss'
import { NavLink } from 'react-router-dom'
import {parseMoney} from '../utils/common.js'

const PromotesItem = ({promoteItem={name:'Promotion one', desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, voluptatum earum officiis ea impedit temporibus?', priceFrom:0, priceTo:100.789}, handleAdd, separator=' - '}) => {
    
    window.scrollTo(0, 0);
    return (
    <div className='promoteItem'>
        <div className="itemPic">
            <img src={promoteItem.pic} alt="item pic" width='100%' />
        </div>
        <div className="itemDetails">
            <h3 className="title">{promoteItem.name}</h3>
            <p className="desc">{promoteItem.desc}</p>
            <button className="itemBtn"><NavLink key={promoteItem.id} to={`/promotion/${promoteItem.id}`}>{promoteItem.name}</NavLink></button>
        </div>
    </div>
    )
}

export default PromotesItem

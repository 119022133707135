import { createSlice } from '@reduxjs/toolkit';

const PartnerSlice = createSlice({
    name: 'partner',
    initialState: {
        partners: [
            {
              id:1,
              pic:'https://picsum.photos/id/15/200',
              name:'Partner One',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 20,
              priceTo: 150,
              isDel: 1
            },
            {
              id:2,
              pic:'https://picsum.photos/id/16/200',
              name:'Partner Two',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 50,
              priceTo: 250,
              isDel: 0
            },
            {
              id:3,
              pic:'https://picsum.photos/id/17/200',
              name:'Partner Three',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 10,
              priceTo: 109,
              isDel: 0
            },
            {
              id:4,
              pic:'https://picsum.photos/id/18/200',
              name:'Partner Four',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 1000,
              priceTo: 15000,
              isDel: 0
            },
            {
              id:5,
              pic:'https://picsum.photos/id/15/200',
              name:'Partner Five',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 20,
              priceTo: 150,
              isDel: 0
            },
            {
              id:6,
              pic:'https://picsum.photos/id/16/200',
              name:'Partner Six',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 50,
              priceTo: 250,
              isDel: 0
            },
            {
              id:7,
              pic:'https://picsum.photos/id/17/200',
              name:'Partner Seven',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 10,
              priceTo: 109,
              isDel: 0
            },
            {
              id:8,
              pic:'https://picsum.photos/id/18/200',
              name:'Partner Eight',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 1000,
              priceTo: 15000,
              isDel: 0
            },
            {
              id:9,
              pic:'https://picsum.photos/id/852/200',
              name:'Partner Nine',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 100000,
              priceTo: 150000,
              isDel: 0
            },
            {
              id:10,
              pic:'https://picsum.photos/id/741/200',
              name:'Partner Ten',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 10000,
              priceTo: 120000,
              isDel: 0
            },
        ],
        
    },
    reducers: {
        updatePartnerName: (state, action) => {
            state.partners.map(partner => {
                if(partner.id === action.payload.partner_id){
                    partner.name = action.payload.name;
                    return partner;
                }
                return partner;
            });
        },
        addPartner: (state, action) => {
            state.partners.push(action.payload);
        },
        deletePartner: (state, action) => {
            state.partners.map(partner => {
                if(partner.id === action.payload.partner_id){
                    partner.isDel = 1;
                    return partner;
                }
                return partner;
            });
        }
    }
})

export const { updatePartnerName, addPartner, deletePartner } = PartnerSlice.actions;

export default PartnerSlice.reducer;
import { FaTimes } from 'react-icons/fa';
import '../assets/RegisterPage.scss'
import LoginPage from './LoginPage';
import { useState, useContext } from 'react';
import UserContext from '../context/UserContext';
import multiavatar from '@multiavatar/multiavatar/esm'

const getAvatar = () => {
    let id = generateRandomString(5, 10)
    return multiavatar(id)
}


function generateRandomString(min, max) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < Math.floor(Math.random() * (min - max) + max); i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
const RegisterPage = ({registerNow, handleRegister}) => {
  const getRandomGradientColor=()=>{
    // Generate random hue for the start and end colors
    var hueStart = Math.floor(Math.random() * 360);
    var hueEnd = (hueStart + Math.floor(Math.random() * 100)) % 360; // Ensures some difference in hues

    // Generate random saturation and lightness values
    var saturation = Math.floor(Math.random() * 50) + 50; // Range between 50 and 100
    var lightness = Math.floor(Math.random() * 30) + 50; // Range between 50 and 80

    // Construct gradient CSS string
    var gradientColor = "linear-gradient(to right, hsl(" + hueStart + ", " + saturation + "%, " + lightness + "%), hsl(" + hueEnd + ", " + saturation + "%, " + lightness + "%))";

    return gradientColor;
}
  window.scrollTo(0, 0);
  const {users, setUsers} = useContext(UserContext)
  const registerUser = (userObj) =>{
    setUsers(prev => {
      let newValue = [...prev, ...[userObj]]
      localStorage.setItem("users", JSON.stringify(newValue))
      return newValue
    })
  }
  let timeId = null
  const handleClose = ()=>{
    clearTimeout(timeId)
    handleRegister()
    setShowLogin(false)
  }
  const [showLogin, setShowLogin] = useState(false)
  const handleShowLogin = ()=>{
    setShowLogin(!showLogin)
  }
  const handleSubmit = (e)=>{
    let nameItem = document.getElementById('name')
    let phoneItem = document.getElementById('phone')
    let emailItem = document.getElementById('email')
    let passwordItem = document.getElementById('password')
    e.preventDefault()
    let confirmPW = document.getElementById('confirmPW');
    let isConfirmPassword = document.getElementById('password').value === confirmPW.value;
    if(isConfirmPassword){
      let formData = {
        name: nameItem.value,
        phone: phoneItem.value,
        email: emailItem.value,
        password: passwordItem.value,
        isLogin: false,
        bgColor: getRandomGradientColor(),
        avatar: getAvatar()
      }
      if(formData.name === '' || formData.phone === '' || formData.email === '' || formData.password === ''){
        alert('Please fill all the fields')
        return
      }
      registerUser(formData);
      timeId = setTimeout(()=>{
        nameItem.value = '' 
        phoneItem.value = '' 
        emailItem.value = '' 
        passwordItem.value = '' 
        confirmPW.value = ''
        handleShowLogin()
      },1000)
    }else{
      alert('Password is not matched, please try again')
      confirmPW.value = ''
    }

  }
  return (
    <div className={`registerNow ${registerNow?'show':'hidden'}`}>
      <div className="registerForm">
        <LoginPage handleRegister={handleRegister} showLogin={showLogin} users={users} handleShowLogin={handleShowLogin} />
        <FaTimes className='closeBtn' onClick={handleClose}/>
        <h5 className='title'>Register Here</h5>
        <form id="registerLogin" onSubmit={handleSubmit}>
          <div className="formItems">
            <div className="formItem">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" placeholder='Enter your name' />
            </div>
            <div className="formItem">
              <label htmlFor="phone">Phone No:</label>
              <input type="text" name="phone" id="phone" placeholder='Enter your phone no' />
            </div>
            <div className="formItem">
              <label htmlFor="email">Email:</label>
              <input type="email" name="email" id="email" placeholder='Enter your email' />
            </div>
            <div className="formItem">
              <label htmlFor="password">Password:</label>
              <input type="password" name="password" id="password" placeholder='Create password' />
            </div>
            <div className="formItem">
              <label htmlFor="confirmPW">Confirm Password:</label>
              <input type="password" name="confirmPW" id="confirmPW" placeholder='Confirm password' />
            </div>
            <div className="formItem">
              <input type="submit" value="Register" className='registerBtn'/>
            </div>
          </div>
        </form>
        <p className='registerText'>Already have an account? <span className='loginNow' onClick={handleShowLogin}>Login Now</span></p>
      </div>
    </div>
  )
}

export default RegisterPage

import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { filteredIsDel } from '../store/ShallowActions'
import '../assets/ServiceDetails.scss'

const ServiceDetail = () => {
    const { id } = useParams()
    const services = useSelector((state)=>filteredIsDel(state.service.services), shallowEqual)
    let currentService = services.find(service => service.id === parseInt(id))
    console.log(currentService);
    window.scrollTo(0, 0);
    return (
    <div className='container down90'>
        <div className="serviceDetails">
            <div className="serviceInfo">
                <div className="serviceDetail">
                    <h1 className="itemTitle">{currentService.name}</h1>
                    <p className="itemPara">{currentService.desc}</p>
                </div>
                <div className="servicesDetailItems">
                    {currentService.details && currentService.details.map((detail, index)=>(
                        <div className="servicedetail" key={index}>{detail}</div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    )
}

export default ServiceDetail
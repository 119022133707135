import '../assets/LoginPage.scss'
import { useContext } from 'react';
import UserContext from '../context/UserContext';
import { useSelector, useDispatch } from 'react-redux';

window.scrollTo(0, 0);
const LoginPage = ({handleShowLogin, showLogin, handleRegister}) => {
  const {users, setUsers, setIsLogin, setCurrentUsers} = useContext(UserContext)
  const regUsers = useSelector((state)=>state.user.regUsers)
  let timeId = null
  const handleBack = ()=>{
    handleShowLogin()
  }
  const loginNow = (userData, allUsers)=>{
    if(allUsers.length < 1){
      alert('No Account Found, please register a new one.')
    }
      let foundUser = allUsers.filter((user)=>user.email === userData.email && user.password === userData.password)
      if(foundUser.length > 0){
          setUsers(prev => prev.map(regUser => {
              if(regUser.email === foundUser[0].email && regUser.password === foundUser[0].password){
                  regUser.isLogin = true
                  setCurrentUsers(regUser)
                  localStorage.setItem("user", JSON.stringify(regUser))
                  return regUser
              }
              return regUser   
          }))
          setIsLogin(true)
          localStorage.setItem("isLogin", JSON.stringify(true))
          return {status: true, data: foundUser[0]}
      }else{
          return {status: false, data: null}
      }
  }
  const handleClose = ()=>{
    clearTimeout(timeId)
    handleShowLogin()
    handleRegister()
  }
  const handleSubmit = (e)=>{
    let emailItem = document.getElementById('emailLogin')
    let passwordItem = document.getElementById('passwordLogin')
    e.preventDefault()
    let loginData = {
      email: emailItem.value,
      password: passwordItem.value,
    }
    if(loginData.email === '' || loginData.password === ''){
      alert('Please fill all the fields')
      return
    }
    let loginUser = loginNow(loginData, users)
    if(loginUser.status){
      timeId = setTimeout(()=>{
        emailItem.value = '' 
        passwordItem.value = ''
        handleClose()
      },1000)
      alert(`Welcome ${loginUser.data.name}`)
      return
    }else{
      alert('Email or password is not matched, please try again')
      passwordItem.value = '' 
      return
    }

  }
  return (
    // <div className={`LoginNow ${showLogin?'show':'hidden'}`}>
      <div className={`loginForm ${showLogin?'show':'hidden'}`}>
        <h5 className='title'>Login Here</h5>
        <form id="loginLogin" onSubmit={handleSubmit}>
          <div className="formItems">
            <div className="formItem">
              <label htmlFor="emailLogin">Email:</label>
              <input type="email" name="emailLogin" id="emailLogin" placeholder='Enter your email' />
            </div>
            <div className="formItem">
              <label htmlFor="passwordLogin">Password:</label>
              <input type="password" name="passwordLogin" id="passwordLogin" placeholder='Key in password' />
            </div>
            <div className="formItem">
              <input type="submit" value="Login" className='loginBtn'/>
            </div>
          </div>
        </form>
        <p className='registerText'>Don't have an account? <span className='RegisterNow' onClick={handleBack}>Register Now</span></p>
      </div>
    // </div>
  )
}

export default LoginPage

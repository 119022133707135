import { createSlice } from '@reduxjs/toolkit';
import multiavatar from '@multiavatar/multiavatar/esm'
// function randomAccess(min, max) {
//     return Math.floor(Math.random() * (min - max) + max);
// }
const getAvatar = () => {
    let id = generateRandomString(5, 10)
    return multiavatar(id)
}


function generateRandomString(min, max) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < Math.floor(Math.random() * (min - max) + max); i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
const UserSlice = createSlice({
    name: 'user',
    initialState: {
        regUsers:[...JSON.parse(localStorage.getItem('users')) || []],
        currentUser: JSON.parse(localStorage.getItem("user"))||{
            name: '',
            email:'',
            password: '',
            user_id:'',
            isLogin: false,
            userInfo:{},
            avatar:''
        },
        loginStatus: JSON.parse(localStorage.getItem("isLogin"))||false
    },
    reducers: {
        updateName: (state, action) => {
            state.currentUser.name = action.payload;
        },
        updateUserId: (state, action) => {
            state.currentUser.user_id = action.payload;
        },
        setUser: (state, action) => {
            state.currentUser.userInfo = action.payload;
        },
        registerUser: (state, action) => {
            Object.defineProperties(action.payload, { avatar: getAvatar() });
            state.regUsers.push(action.payload);
            localStorage.setItem("regUsers", JSON.stringify(state.regUsers));
        },
        loginUser: (state, action) => {
            let foundUser = state.regUsers.filter(user => user.email === action.payload.email && user.password === action.payload.password);
            if(foundUser.length > 0){
                state.currentUser = foundUser[0]
                state.currentUser.isLogin = true;
                state.loginStatus = true;
                return true;
            }else{
                return false;
            }
        },
        logoutUser: (state) => {
            state.loginStatus = false;
            state.currentUser = {
                name: '',
                email:'',
                password: '',
                user_id:'',
                isLogin: false,
                userInfo:{}
            }
        }
    }
})

export const { getAllUsers, updateName, updateAge, registerUser, loginUser, logoutUser, setUser, updateUserId } = UserSlice.actions;

export default UserSlice.reducer;
import '../assets/CaseItem.scss'
import { NavLink } from 'react-router-dom';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { getStar } from '../utils/common';

const CasesItem = ({caseItem={name:'Case one', desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, voluptatum earum officiis ea impedit temporibus?', priceFrom:0, priceTo:100.789}, separator=' - '}) => {
    
    window.scrollTo(0, 0);
    let { full, half } = getStar(caseItem.rating);
    return (
    <div className='caseItem'>
        <div className="itemPic">
            <img src={caseItem.pic} alt="item pic" width='100%' />
        </div>
        <div className="itemDetails">
            <h3 className="title">{caseItem.name}</h3>
            <p className="desc">{caseItem.desc}</p>
            <h4 className="rating">
            Rating: 
            <span className="starRating">
                {Array.from({ length: full }).map((_, i) => <FaStar key={i} />)}
                {Array.from({ length: half }).map((_, i) => <FaStarHalfAlt key={i} />)}
            </span>
            </h4>
            <button className="itemBtn"><NavLink key={caseItem.id} to={`/case-study/${caseItem.id}`}>{caseItem.name}</NavLink></button>
        </div>
    </div>
    )
}

export default CasesItem

import {useContext, useEffect} from 'react'
import '../assets/MemberCenter.scss'
import { setTitleTagText } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext'

window.scrollTo(0, 0);
const MemberCenter = () => {
  const {isLogin} = useContext(UserContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (!isLogin) {
      navigate('/')
    }
  })
  setTitleTagText('Member Center | Find Services | Find Partners | Promotions');
  return (
    <div className="bg">
      <div className='container down90'>
        <div className="memberCenter">
          <h1 className='title'>Member Center</h1>
          <div className="memberSettings">
            <ul className="settings">
              <li className="setting">Business Info</li>
              <li className="setting">Products Info</li>
              <li className="setting">Services Info</li>
              <li className="setting">Promotions Info</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberCenter

import { createContext, useState } from "react";

const ServiceContext = createContext()

export const ServiceProvider = ({children}) => {
    
    const [services, setServices] = useState(
        [
          {
            id:1,
            pic:'https://picsum.photos/id/15/200',
            name:'Service One',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 20,
            priceTo: 150,
          },
          {
            id:2,
            pic:'https://picsum.photos/id/16/200',
            name:'Service Two',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 50,
            priceTo: 250,
          },
          {
            id:3,
            pic:'https://picsum.photos/id/17/200',
            name:'Service Three',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 10,
            priceTo: 109,
          },
          {
            id:4,
            pic:'https://picsum.photos/id/18/200',
            name:'Service Four',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 1000,
            priceTo: 15000,
          },
          {
            id:5,
            pic:'https://picsum.photos/id/15/200',
            name:'Service Five',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 20,
            priceTo: 150,
          },
          {
            id:6,
            pic:'https://picsum.photos/id/16/200',
            name:'Service Six',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 50,
            priceTo: 250,
          },
          {
            id:7,
            pic:'https://picsum.photos/id/17/200',
            name:'Service Seven',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 10,
            priceTo: 109,
          },
          {
            id:8,
            pic:'https://picsum.photos/id/18/200',
            name:'Service Eight',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 1000,
            priceTo: 15000,
          },
          {
            id:9,
            pic:'https://picsum.photos/id/852/200',
            name:'Service Nine',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 100000,
            priceTo: 150000,
          },
          {
            id:10,
            pic:'https://picsum.photos/id/741/200',
            name:'Service Ten',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 10000,
            priceTo: 120000,
          },
        ]
      )
    // const [loading, setLoading] = useState(true)

    // const fetchServices = async () => {
    //     const response = await fetch('http://localhost:5000/services')
    //     const data = await response.json()
    //     setServices(data)
    //     setLoading(false)
    // }
    const serviceMoneyFormat =(num)=>{
      let convertedNum=''
      if(`${parseFloat(num).toFixed(2)}`.length  >= 4){
          `${parseFloat(num).toFixed(2)}`.split('.')[0].split('').reverse().forEach((item, index)=>{
              if((index)%3===0 && index !== `${parseFloat(num).toFixed(2)}`.split('.')[0].split('').length && index !==0){
                  convertedNum += `,`
              }
              convertedNum += `${item}`
          })
          return `${convertedNum}`.split('').reverse().join('')+`.${`${parseFloat(num).toFixed(2)}`.split('.')[1]}`
      }
      return `${parseFloat(num).toFixed(2)}`
    }
    return (
        <ServiceContext.Provider value={{services, setServices, serviceMoneyFormat}}>
            {children}
        </ServiceContext.Provider>
    )
}

export default ServiceContext;
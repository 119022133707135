import { createSlice } from '@reduxjs/toolkit';

const ServiceSlice = createSlice({
    name: 'service',
    initialState: {
        services: [
            {
              id:1,
              name:'Outsourced Sales & Marketing',
              desc:'Outsourced sales and marketing is a service that provides companies with the resources and expertise needed to increase their sales and marketing efforts.',
              details:['Marketing Strategy','Sales Strategy','Sales Pipeline Management','Customer Relationship Management','Lead Generation','Content Marketing','Email Marketing','Social Media Marketing','SEO','PPC'],
              isDel: 0
            },
            {
              id:2,
              name:'Product & Service Promotion',
              desc:'Product and service promotion is a service that provides companies with the resources and expertise needed to promote their services & products.',
              details:['Product Launch','Service Launch','Marketing Campaigns','Brand Building','Public Relations','Exhibitions & Conferences','Trade Shows','B2B Marketing','B2C Marketing'],
              isDel: 0
            },
            {
              id:3,
              name:'Foreigners Entrepreneurship',
              desc:'Assist Foreign entrepreneurs who start and operate businesses in a foreign country.',
              details:['Business Registration','Taxation','Legal Support','Business Plan','Business Strategy','Business Development','Business Expansion','Business Management','Business Consultancy'],
              isDel: 0
            },
            {
              id:1,
              name:'Business Management Service',
              desc:'Business management service is a service that provides companies with the resources and expertise needed to manage their business operations.',
              details:['Business Registration','Taxation','Legal Support','Business Plan','Business Strategy','Business Development','Business Expansion','Business Management','Business Consultancy'],
              isDel: 0
            },
        ],
        
    },
    reducers: {
        updateserviceName: (state, action) => {
            state.services.map(service => {
                if(service.id === action.payload.service_id){
                    service.name = action.payload.name;
                    return service;
                }
                return service;
            });
        },
        addservice: (state, action) => {
            state.services.push(action.payload);
        },
        deleteservice: (state, action) => {
            state.services.map(service => {
                if(service.id === action.payload.service_id){
                    service.isDel = 1;
                    return service;
                }
                return service;
            });
        }
    }
})

export const { updateserviceName, addservice, deleteservice } = ServiceSlice.actions;

export default ServiceSlice.reducer;
export const parseMoney =(num)=>{
    let convertedNum=''
    if(`${parseFloat(num).toFixed(2)}`.length  >= 4){
        `${parseFloat(num).toFixed(2)}`.split('.')[0].split('').reverse().forEach((item, index)=>{
            if((index)%3===0 && index !== `${parseFloat(num).toFixed(2)}`.split('.')[0].split('').length && index !==0){
                convertedNum += `,`
            }
            convertedNum += `${item}`
        })
        return `${convertedNum}`.split('').reverse().join('')+`.${`${parseFloat(num).toFixed(2)}`.split('.')[1]}`
    }
    return `${parseFloat(num).toFixed(2)}`
}

export const setTitleTagText = (text) =>{
       document.title = `ConnectCo Co Pte Ltd | ${text}`
}

export const getStar = (rating)=>{
    let fullStarNum = Math.floor(rating);
    let halfStarNum = (rating - fullStarNum)*2;
    return {
        full: fullStarNum,
        half: halfStarNum
    }
}
import '../../assets/Footer.scss'
import Navbar from './Navbar'
const Footer = () => {
  return (
    <>
      <footer>Footer</footer>
      <Navbar isMobile={true}/>
    </>
  )
}

export default Footer
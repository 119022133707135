import '../assets/ServiceItem.scss'
import { NavLink } from 'react-router-dom';
import { FaBezierCurve } from 'react-icons/fa';

const ServicesItem = ({serviceItem={name:'Service one', desc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, voluptatum earum officiis ea impedit temporibus?', priceFrom:0, priceTo:100.789}, handleAdd, separator=' - '}) => {
    
    window.scrollTo(0, 0);
    return (
    <div className='serviceItem'>
        <div className="itemPic">
            <FaBezierCurve/>
        </div>
        <div className="itemDetails">
            <h3 className="title">{serviceItem.name}</h3>
            <h3 className="desc">{serviceItem.desc}</h3>
            <button className="itemBtn"><NavLink key={serviceItem.id} to={`/services/${serviceItem.id}`}>{serviceItem.name}</NavLink></button>
        </div>
    </div>
    )
}

export default ServicesItem

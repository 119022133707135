import '../assets/SearchResults.scss';
// import PartnersItem from './PartnersItem';
import PromotesItem from './PromoteItem';
import { shallowEqual, useSelector } from 'react-redux';
import { filteredIsDel } from '../store/ShallowActions';
import { useEffect, useState } from 'react';

const SearchResult = ({dataFilter}) => {
    const promotionData = useSelector((state)=>filteredIsDel(state.promotion.promotions), shallowEqual);
    const [result, setResult] = useState([])
    useEffect(()=>{
        let temResultName = promotionData.filter(item=> `${`${item.name}`.toLowerCase()}`.includes(dataFilter.searchItem?dataFilter.searchItem.toLowerCase():''))
        let temResultDesc = promotionData.filter(item=> !`${`${item.name}`.toLowerCase()}`.includes(dataFilter.searchItem?dataFilter.searchItem.toLowerCase():'')).filter(item=> `${`${item.desc}`.toLowerCase()}`.includes(dataFilter.searchItem?dataFilter.searchItem.toLowerCase():''))
        setResult([...temResultName, ...temResultDesc])
    }, [dataFilter.searchItem])
    return (
    <>
    <h2 className='searchResultsTitle'>{dataFilter.searchItem ?`Found ${result.length} result(s) for: ${dataFilter.searchItem}`:'All Promotions'}</h2>
    <div className={result.length > 0?"searchResults":"searchResults NoResult"}>
        {dataFilter.searchItem ? result.length > 0? result.map((item, index)=> <PromotesItem key={index} promoteItem={item} />): 'No Promotion found...' : promotionData&&promotionData.map((promotion, index)=>
        (
            <PromotesItem promoteItem={promotion} key={index}/>
        )
        )}
    </div>
    </>
    )
}

export default SearchResult

import { Autoplay, Pagination, A11y, Navigation } from 'swiper/modules';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../assets/Banner.scss';
import CasesItem from './CasesItem';
import Reviews from './Reviews';

const Banner = ({type='promotion', list=[]}) => {
  const promotionsSlides = (
    <>
    <Swiper
      // install Swiper modules
      modules={[Autoplay, Pagination, A11y]}
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true, renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      } }}
    //   onSwiper={(swiper) => console.log(swiper)}
    //   onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><div className="promotions"><div className="promoTitle"><h1>Promotion1</h1></div><div className="promoSubDetail"><p className="content">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus corrupti, officia mollitia sit eveniet dicta.</p><a href="/" className="promoBtn">Check More</a></div></div></SwiperSlide>
      <SwiperSlide><div className="promotions reverse"><div className="promoTitle"><h1>Promotion2</h1></div><div className="promoSubDetail"><p className="content">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus corrupti, officia mollitia sit eveniet dicta.</p><a href="/" className="promoBtn">Check More</a></div></div></SwiperSlide>
      <SwiperSlide><div className="promotions"><div className="promoTitle"><h1>Promotion3</h1></div><div className="promoSubDetail"><p className="content">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus corrupti, officia mollitia sit eveniet dicta.</p><a href="/" className="promoBtn">Check More</a></div></div></SwiperSlide>
      <SwiperSlide><div className="promotions reverse"><div className="promoTitle"><h1>Promotion4</h1></div><div className="promoSubDetail"><p className="content">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus corrupti, officia mollitia sit eveniet dicta.</p><a href="/" className="promoBtn">Check More</a></div></div></SwiperSlide>
      <SwiperSlide><div className="promotions"><div className="promoTitle"><h1>Promotion5</h1></div><div className="promoSubDetail"><p className="content">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus corrupti, officia mollitia sit eveniet dicta.</p><a href="/" className="promoBtn">Check More</a></div></div></SwiperSlide>
  </Swiper>
  </>
  )
  const clientReviewsSlides = (
    <>
    <Swiper
      // install Swiper modules
      modules={[Navigation, Autoplay, A11y]}
      // spaceBetween={15}
      // slidesPerView={4}
      className={type === 'client'? type:''}
      navigation={true}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        375: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        1090: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      }}
    //   onSwiper={(swiper) => console.log(swiper)}
    //   onSlideChange={() => console.log('slide change')}
    >
      {list.map((item, index)=> <SwiperSlide key={index}><CasesItem key={`c-${index}`} caseItem={item} /></SwiperSlide>)}
    </Swiper>
  </>
  )
  const reviewsSlides = (
    <>
    <Swiper
      // install Swiper modules
      modules={[Navigation, Autoplay, A11y]}
      // spaceBetween={15}
      // slidesPerView={4}
      className={type === 'reviews'? type:''}
      navigation={true}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        375: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
        800: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
        1090: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }}
    //   onSwiper={(swiper) => console.log(swiper)}
    //   onSlideChange={() => console.log('slide change')}
    >
      {list.map((item, index)=> <SwiperSlide key={index}><Reviews key={`c-${index}`} reviewItem={item} /></SwiperSlide>)}
    </Swiper>
  </>
  )
  return type !== 'promotion' ? (type === 'client'?clientReviewsSlides:reviewsSlides) : promotionsSlides;
}

export default Banner
    import { NavLink } from 'react-router-dom'
    import '../../assets/Nav.scss'
    import {FaStore, FaSuitcase, FaHome, FaUser, FaPowerOff, FaHandshake, FaInfoCircle} from 'react-icons/fa'
    import { FaPeopleGroup } from 'react-icons/fa6'
    import UserContext from '../../context/UserContext'
    import { useContext, useState } from 'react';

    const Navbar = ({isMobile=false, handleRegister}) => {
        const {user, isLogin, setIsLogin} = useContext(UserContext)
        const handleClick = ()=>{
            handleRegister()
        }
        const [closeprofile, setCloseprofile] = useState(false)
        const closeMyProfile = ()=>{
            setCloseprofile(false)
            document.body.classList.remove('freeze')
        }
        const toggleProfile = ()=>{
            setCloseprofile(prev=>{
                if(prev === false){
                    document.body.classList.add('freeze')
                }
                return !prev
            })
        }
        const [showSetting, setShowSetting] = useState(false)
        const toggleUserSetting =()=>{
            setShowSetting(prev=>!prev)
        }
        const closeIfBackClick = e =>{
            if(e.target.classList.contains('profileModal')){
                closeMyProfile()
            }
            return;
        }
        const getRandomGradientColor=()=>{
            // Generate random hue for the start and end colors
            var hueStart = Math.floor(Math.random() * 360);
            var hueEnd = (hueStart + Math.floor(Math.random() * 100)) % 360; // Ensures some difference in hues

            // Generate random saturation and lightness values
            var saturation = Math.floor(Math.random() * 50) + 50; // Range between 50 and 100
            var lightness = Math.floor(Math.random() * 30) + 50; // Range between 50 and 80

            // Construct gradient CSS string
            var gradientColor = "linear-gradient(to right, hsl(" + hueStart + ", " + saturation + "%, " + lightness + "%), hsl(" + hueEnd + ", " + saturation + "%, " + lightness + "%))";

            return gradientColor;
        }
        console.log(getRandomGradientColor(), 'getRandomGradientColor()');
        const userBg = getRandomGradientColor()
        const logout =()=>{
            setIsLogin(false)
            localStorage.removeItem('user')
            localStorage.setItem('isLogin', false)
            setShowSetting(false)
        }
        const onMouseLeaveClose = ()=>{
            setShowSetting(false)
        }
        if(isMobile){
            return (
                <nav className="mainNav mobile">
                    <div className="navWrap">
                        <ul className="menu">
                            <li className="menuLink">
                                <NavLink
                                // exact
                                to="/"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                ><FaHome /></NavLink>
                            </li>
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/services"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                ><FaStore/></NavLink>
                            </li>
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/partners"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                ><FaHandshake /></NavLink>
                            </li>
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/case-study"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                ><FaSuitcase /></NavLink>
                            </li>
                            {/* <li className="menuLink">
                            <NavLink
                                // exact
                                to="/member-center"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                ><FaPeopleGroup /></NavLink>
                            </li> */}
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/about-us"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                ><FaInfoCircle /></NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
                )
        }else{
            return (
                <>
                <nav className="mainNav">
                    <div className="navWrap">
                        <NavLink
                            // exact
                            to="/"
                            className='logo'
                        >
                            <img src="/assets/images/logo-connect.png" alt="main logo" className="logoImg" width="100%" />
                        </NavLink>
                        <ul className="menu">
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/partners"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                >Become a Partner</NavLink>
                            </li>
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/services"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                >Services</NavLink>
                            </li>
                            <li className="menuLink">
                            <NavLink
                                // exact
                                to="/case-study"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                >Case Study</NavLink>
                            </li>
                            <li className="menuLink">
                                <NavLink
                                // exact
                                to="/about-us"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                >About Us</NavLink>
                            </li>
                            {/* <li className="menuLink">
                            <NavLink
                                // exact
                                to="/member-center"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                                >Member Center</NavLink>
                            </li> */}
                            {!isLogin ? (
                                <li className="menuLink mobile">
                                    <button className="registerBtn" onClick={handleClick}>
                                        Register / Login
                                    </button>
                                </li>
                            ):(
                                <>
                                    <li className="menuLink mobile">
                                        {user.avatar?(<div className="userAvatar" onClick={toggleUserSetting} dangerouslySetInnerHTML={{__html:user.avatar}}></div>):(
                                            <div className='username' onClick={toggleUserSetting} style={{background:user.bgColor?user.bgColor:userBg}}>{`${user.name}`.charAt(0).toUpperCase()+`${user.name}`.charAt(`${user.name}`.length-1).toUpperCase()}</div>
                                        )}
                                    </li>
                                    <div className={`userSetting ${showSetting?'show':''}`} onMouseLeave={onMouseLeaveClose}>
                                        <div className="settingItem" onClick={toggleProfile}><FaUser className='logoutIcon' /><span>My Profile</span></div>
                                        <div className="settingItem">
                                        <NavLink
                                        // exact
                                        to="/member-center"
                                        className='settingLink'
                                        ><FaPeopleGroup className='logoutIcon' /><span>Member Center</span></NavLink>
                                        </div>
                                        <div className="settingItem" onClick={logout}><FaPowerOff className='logoutIcon' /><span>Logout</span></div>
                                    </div>
                                </>
                            )}
                        </ul>
                    </div>
                </nav>
                <div className={`profileModal ${closeprofile?'show':''}`} onClick={closeIfBackClick}>
                    <div className="myProfile">
                        <h2 className="profileTitle">Profile <span onClick={closeMyProfile} className="closeProfile">&times;</span></h2>
                        <div className="profileItem">
                            {user.avatar?(<div className="profilePic" dangerouslySetInnerHTML={{__html:user.avatar}}></div>):(<div className="profilePic" style={{background:user.bgColor?user.bgColor:userBg}}>{`${user.name}`.charAt(0)+`${user.name}`.charAt(`${user.name}`.length-1).toUpperCase()}</div>)}
                            <div className="profileName">{user.name}</div>
                        </div>
                        <div className="profileItem">
                            <div className="profileLabel">Email: </div>
                            <div className="profileInput">{user.email}</div>
                        </div>
                        <div className="profileItem">
                            <div className="profileLabel">Phone: </div>
                            <div className="profileInput">{user.phone}</div>
                        </div>
                    </div>
                </div>
                </>
                ) 
        }
    }

    export default Navbar
import { useParams } from 'react-router-dom'
import '../assets/PromoteDetails.scss'
import { shallowEqual, useSelector } from 'react-redux'
import { filteredIsDel } from '../store/ShallowActions'

const PromotionDetail = () => {
    const promotions = useSelector(state => filteredIsDel(state.promotion.promotions), shallowEqual)
    const { id } = useParams()
    let currentPromotion = promotions.find(promotion => promotion.id === parseInt(id))
    window.scrollTo(0, 0);
    return (
    <div className='container down90'>
        <div className="promotionDetails">
            <div className="promotionInfo">
                <div className="promotionPreview">
                    <img src={currentPromotion.pic} alt={currentPromotion.pic} width='100%'/>
                </div>
                <div className="promotionDetail">
                    <h1 className="itemTitle">{currentPromotion.name}</h1>
                    <p className="itemPara">{currentPromotion.desc}</p>
                    <h3 className="priceRange">
                        Price Range: 
                        <span className="priceFrom">${currentPromotion.priceFrom}</span>
                        <span className="seperator"> - </span>
                        <span className="priceTo">${currentPromotion.priceTo}</span>
                    </h3>
                </div>
            </div>
            {/* <div className="promotionComments">
                <div className="comment"></div>
                <div className="commentForm"></div>
            </div> */}
        </div>
    </div>
    )
}

export default PromotionDetail
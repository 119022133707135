import '../../assets/PageHeader.scss'

const PageHeader = ({pageTitle='Welcome to ConnectCo', img='/assets/images/mainBg.webp'}) => {

    return (
    <div className="pageHeader">
        <img src={img} alt="mainBg" className="pageHeaderImg" width="100%" />
        <h1 className="pageTitle">{pageTitle}</h1>
    </div>
    )
}

export default PageHeader
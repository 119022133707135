import ServicesItem from '../components/ServicesItem';
import PageHeader from '../components/layout/PageHeader';
import '../assets/AllServices.scss'
import { setTitleTagText } from '../utils/common';
import { useSelector, shallowEqual } from 'react-redux';
import { filteredIsDel } from '../store/ShallowActions';

window.scrollTo(0, 0);
const Services = () => {
  const services = useSelector((state)=>filteredIsDel(state.service.services), shallowEqual);
  setTitleTagText('Services | Find Services | Find Partners | Promotions');
  return (
    <>
      <PageHeader pageTitle="Services Available" img="/assets/images/serviceHead.webp"/>
      <div className='container down90'>
        <div className="allServices">
          {services && services.map((service, index)=>(
            <ServicesItem serviceItem={service} key={index} />
          ))}
        </div>
      </div>
    </>
  )
}

export default Services

import { createContext, useState } from "react";

const PartnerContext = createContext()

export const PartnerProvider = ({children}) => {
    
    const [partners, setPartners] = useState(
        [
          {
            id:1,
            pic:'https://picsum.photos/id/15/200',
            name:'Partner One',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 20,
            priceTo: 150,
          },
          {
            id:2,
            pic:'https://picsum.photos/id/16/200',
            name:'Partner Two',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 50,
            priceTo: 250,
          },
          {
            id:3,
            pic:'https://picsum.photos/id/17/200',
            name:'Partner Three',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 10,
            priceTo: 109,
          },
          {
            id:4,
            pic:'https://picsum.photos/id/18/200',
            name:'Partner Four',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 1000,
            priceTo: 15000,
          },
          {
            id:5,
            pic:'https://picsum.photos/id/15/200',
            name:'Partner Five',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 20,
            priceTo: 150,
          },
          {
            id:6,
            pic:'https://picsum.photos/id/16/200',
            name:'Partner Six',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 50,
            priceTo: 250,
          },
          {
            id:7,
            pic:'https://picsum.photos/id/17/200',
            name:'Partner Seven',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 10,
            priceTo: 109,
          },
          {
            id:8,
            pic:'https://picsum.photos/id/18/200',
            name:'Partner Eight',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 1000,
            priceTo: 15000,
          },
          {
            id:9,
            pic:'https://picsum.photos/id/852/200',
            name:'Partner Nine',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 100000,
            priceTo: 150000,
          },
          {
            id:10,
            pic:'https://picsum.photos/id/741/200',
            name:'Partner Ten',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
            priceFrom: 10000,
            priceTo: 120000,
          },
        ]
      )
    // const [loading, setLoading] = useState(true)

    // const fetchPartners = async () => {
    //     const response = await fetch('http://localhost:5000/Partners')
    //     const data = await response.json()
    //     setPartners(data)
    //     setLoading(false)
    // }
    const partnerMoneyFormat =(num)=>{
      let convertedNum=''
      if(`${parseFloat(num).toFixed(2)}`.length  >= 4){
          `${parseFloat(num).toFixed(2)}`.split('.')[0].split('').reverse().forEach((item, index)=>{
              if((index)%3===0 && index !== `${parseFloat(num).toFixed(2)}`.split('.')[0].split('').length && index !==0){
                  convertedNum += `,`
              }
              convertedNum += `${item}`
          })
          return `${convertedNum}`.split('').reverse().join('')+`.${`${parseFloat(num).toFixed(2)}`.split('.')[1]}`
      }
      return `${parseFloat(num).toFixed(2)}`
    }
    return (
        <PartnerContext.Provider value={{partners, setPartners, partnerMoneyFormat}}>
            {children}
        </PartnerContext.Provider>
    )
}

export default PartnerContext;
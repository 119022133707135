import { useParams } from 'react-router-dom'
import '../assets/CaseDetails.scss'
import { useSelector, shallowEqual } from 'react-redux';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { filteredItem } from '../store/ShallowActions';
import { getStar } from '../utils/common';
import Banner from './Banner';


const CaseDetail = () => {
    const { id } = useParams()
    // eslint-disable-next-line
    const currentCase = useSelector((state)=>filteredItem(state.cases.cases, id), shallowEqual);
    const { full, half } = getStar(currentCase.rating);
    window.scrollTo(0, 0);
    return (
    <div className='container down90'>
        {currentCase ? (
            <div className="caseDetails">
                <div className="caseInfo">
                    <div className="casePreview">
                        <img src={currentCase.pic} alt={currentCase.pic} width='100%'/>
                    </div>
                    <div className="caseDetail">
                        <h1 className="itemTitle">{currentCase.name}</h1>
                        <p className="itemPara">{currentCase.desc}</p>
                        <h3 className="rating">
                        Rating: 
                        <span className="starRating">
                            {Array.from({ length: full }).map((_, i) => <FaStar key={i} />)}
                            {Array.from({ length: half }).map((_, i) => <FaStarHalfAlt key={i} />)}
                        </span>
                        </h3>
                        <div className="customerReviews">
                            <Banner type='reviews' list={currentCase.reviews} />
                        </div>
                    </div>
                </div>
                {/* <div className="caseComments">
                    <div className="comment"></div>
                    <div className="commentForm"></div>
                </div> */}
            </div>
            ) 
            :
            <div className="notFound">
                Case not found...
            </div>
        }
    </div>
    )
}

export default CaseDetail
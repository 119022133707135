import { createSlice } from '@reduxjs/toolkit';

const PromotionSlice = createSlice({
    name: 'promotion',
    initialState: {
        promotions: [
            {
              id:1,
              pic:'https://picsum.photos/id/15/200',
              name:'Promotion One',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 20,
              priceTo: 150,
              isDel: 1
            },
            {
              id:2,
              pic:'https://picsum.photos/id/16/200',
              name:'Promotion Two',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 50,
              priceTo: 250,
              isDel: 0
            },
            {
              id:3,
              pic:'https://picsum.photos/id/17/200',
              name:'Promotion Three',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 10,
              priceTo: 109,
              isDel: 0
            },
            {
              id:4,
              pic:'https://picsum.photos/id/18/200',
              name:'Promotion Four',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 1000,
              priceTo: 15000,
              isDel: 0
            },
            {
              id:5,
              pic:'https://picsum.photos/id/15/200',
              name:'Promotion Five',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 20,
              priceTo: 150,
              isDel: 0
            },
            {
              id:6,
              pic:'https://picsum.photos/id/16/200',
              name:'Promotion Six',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 50,
              priceTo: 250,
              isDel: 0
            },
            {
              id:7,
              pic:'https://picsum.photos/id/17/200',
              name:'Promotion Seven',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 10,
              priceTo: 109,
              isDel: 0
            },
            {
              id:8,
              pic:'https://picsum.photos/id/18/200',
              name:'Promotion Eight',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 1000,
              priceTo: 15000,
              isDel: 0
            },
            {
              id:9,
              pic:'https://picsum.photos/id/852/200',
              name:'Promotion Nine',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 100000,
              priceTo: 150000,
              isDel: 0
            },
            {
              id:10,
              pic:'https://picsum.photos/id/741/200',
              name:'Promotion Ten',
              desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure mollitia corporis deserunt sed sit quisquam cupiditate nobis ducimus quasi quos.',
              priceFrom: 10000,
              priceTo: 120000,
              isDel: 0
            },
        ],
        
    },
    reducers: {
        updatePromotionName: (state, action) => {
            state.promotions.map(promotion => {
                if(promotion.id === action.payload.promotion_id){
                    promotion.name = action.payload.name;
                    return promotion;
                }
                return promotion;
            });
        },
        addPromotion: (state, action) => {
            state.promotions.push(action.payload);
        },
        deletePromotion: (state, action) => {
            state.promotions.map(promotion => {
                if(promotion.id === action.payload.promotion_id){
                    promotion.isDel = 1;
                    return promotion;
                }
                return promotion;
            });
        }
    }
})

export const { updatePromotionName, addPromotion, deletePromotion } = PromotionSlice.actions;

export default PromotionSlice.reducer;